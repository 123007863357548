import React, { Component } from "react";
import Column from "../components/common/columns";
import { Paginated } from "../services/Paginated";
import withRouter from "../utils/withRouter";
import AuditTable from "./components/auditTable";
import auditService, { AuditLogEntry } from "../services/auditService";
import equal from "fast-deep-equal";
import Loading from "../components/common/Loading";

interface AuditState {
  loaded: boolean;
  pagedData: Paginated<AuditLogEntry>;
  sortColumn: Column<AuditLogEntry>;
  filters: Map<string, string>;
}

class Audit extends Component<any, any, AuditState> {
  state = {
    loaded: false,
    pagedData: { page: 1, pageSize: 10, count: 0, totalPages: 1, data: [] },
    sortColumn: { key: "dateTime", label: "Timing", order: "desc" },
    filters: new Map<string, string>(),
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: AuditState | undefined
  ): void {
    if (
      !(
        equal(
          this.props?.router.location.search,
          prevProps?.router.location.search
        ) &&
        equal(
          this.props?.router.params.auditId,
          prevProps?.router.params.auditId
        )
      )
    ) {
      let { pagedData, sortColumn } = this.state;

      pagedData = { page: 1, pageSize: 10, count: 0, totalPages: 1, data: [] };
      sortColumn = { key: "dateTime", label: "Timing", order: "desc" };

      this.setState({ pagedData, sortColumn });

      this.changePage(pagedData.page, pagedData.pageSize);
    }
  }

  loadData = async () => {
    const { page, pageSize } = this.state.pagedData;

    await this.changePage(page, pageSize);
  };

  changePage = async (page: number, pageSize: number) => {
    const { pagedData } = this.state;

    pagedData.page = page;
    pagedData.pageSize = pageSize;

    this.setState({ loaded: false, pagedData });
    await this.doSearch();
  };

  onSort = async (sortColumn: Column<AuditLogEntry>) => {
    this.setState({ loaded: false, sortColumn });

    await this.doSearch();
  };

  onSearch = async (name: string, value: string) => {
    const { filters } = this.state;
    filters.set(name, value);

    this.setState({ filters });

    await this.doSearch();
  };

  doSearch = async () => {
    const { page, pageSize } = this.state.pagedData;
    const { sortColumn, filters } = this.state;
    const auditId = this.props?.router?.params?.auditId ?? "";
    var primaryOnly = this.props.router.query.get("primaryOnly");
    const isPrimaryOnly: boolean =
      primaryOnly === null || primaryOnly.toLowerCase() === "true";

    const pagedData = await auditService.getLog(
      auditId,
      isPrimaryOnly,
      page,
      pageSize,
      sortColumn.key,
      sortColumn.order === "asc",
      filters
    );
    if (pagedData) {
      this.setState({ loaded: true, pagedData });
    } else {
      this.setState({ loaded: false });
    }
  };

  render(): JSX.Element {
    const { loaded, pagedData, sortColumn } = this.state;

    return (
      <Loading loaded={loaded}>
        <div>
          <AuditTable
            data={pagedData}
            sortColumn={sortColumn}
            onChangePage={this.changePage}
            onSort={this.onSort}
            onSearch={this.onSearch}
          />
        </div>
      </Loading>
    );
  }
}

const HOCAudit = withRouter(Audit);

export default HOCAudit;
