import React from "react";
import LoadingPanel from "./LoadingPanel";

interface Loading2Props {
    loaded : boolean
    children: React.ReactNode;
}
 
interface Loading2State {
}

class Loading extends React.Component<Loading2Props, Loading2State> {   
    state = { loaded : false }

    render() {
        const { loaded, children } = this.props;

        if (!loaded) {
            return (<LoadingPanel/>)
        }
        else {
            return (<>{children}</>);
        }
    }
}
 
export default Loading;