import httpService from "./httpService";
import { Paginated } from "./Paginated";
import MapToJson from "../utils/MapToJson";

const apiEndpoint = "/Audit";

export interface AuditLogEntry {
  id: bigint;
  userId: bigint;
  userDisplayName: string;
  type: string;
  dateTime: Date;
  fields: string;
  comment: string;
  entityName: string;
  primaryKey: string;
  entityDisplayName: string;
  displayName: string;
}

export async function getLog(
  logEntry: string,
  primaryOnly: boolean,
  page: number,
  pageSize: number,
  sortKey: string,
  sortAscending: boolean,
  filters?: Map<string, string> | undefined
): Promise<Paginated<AuditLogEntry>> {
  const filterString = MapToJson(filters);
  const response = await httpService.get<Paginated<AuditLogEntry>>(
    apiEndpoint + "/log",
    {
      params: {
        page: page,
        pageSize: pageSize,
        sortKey: sortKey,
        sortAscending: sortAscending,
        filters: filterString,
        logEntry: logEntry,
        primaryOnly: primaryOnly,
      },
    }
  );
  return response?.data;
}

const auditService = {
  getLog,
};

export default auditService;
