import { useEffect } from "react";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider, HtmlProps } from "react-helmet-async";
import Frame from "./frame/frame";
import config from "./config.json";
import EnvPage from "./envpage/Env";
import NotFound from "./notFound/NotFound";
import authentication from "./services/authenticationService";
import LoginForm from "./frame/LoginForm";
import Logout from "./frame/Logout";
import Redirect from "./components/common/Redirect";
import ForgotPassword from "./frame/ForgotPassword";
import EmailUserAction from "./frame/EmailUserAction";
import HomePage from "./frame/HomePage";
import Profile from "./profile/Profile";
import UserDetails from "./users/UserDetails";
import Users from "./users/users";
import Library from "./library/library";
import LibraryViewer from "./libraryViewer/libraryViewer";
import LibraryDetails from "./library/libraryDetails";
import Instruments from "./instrument/instruments";
import InstrumentDetails from "./instrument/instrumentDetails";
import { ToastContainer } from "react-toastify";
import LibraryPieces from "./libraryPieces/libraryPieces";
import LibraryPieceDetails from "./libraryPieces/libraryPieceDetails";
import LibraryParts from "./libraryParts/libraryPartss";
import LibraryPartDetails from "./libraryParts/libraryPartDetails";
import InstrumentSynonyms from "./instrumentSynonyms/instrumentSynonyms";
import InstrumentSynonymDetails from "./instrumentSynonyms/instrumentSynonymDetails";
import PartInstruments from "./partInstruments/partInstruments";
import theme from "./utils/theme";
import LoggedOutFrame from "./frame/LoggedOutFrame";
import PartInstrumentDetails from "./partInstruments/partInstrumentDetails";
import Security from "./security/Security";
import Performance from "./performance/Performance";
import PerformanceDetails from "./performance/PerformanceDetails";
import AddRole from "./security/AddRole";
import RoleDetails from "./security/RoleDetails";
import AddRoleUser from "./security/AddRoleUser";
import authenticationService from "./services/authenticationService";
import AddUserRole from "./users/AddUserRole";
import SsoManager from "./ssoManager/ssoManager";
import SsoProviderDetails from "./ssoManager/SsoProviderDetails";
import AuditLog from "./audit/audit";

function GetSecureRoutes() {
  const profileRoute = window.__RUNTIME_CONFIG__.EXTERNAL_LOGIN ? (
    <Route path="/profile" element={<Redirect to="/account/profile" />} />
  ) : (
    <Route
      path="/profile"
      element={
        <Frame>
          <Profile />
        </Frame>
      }
    />
  );

  return (
    <>
      {authenticationService.hasAccess("AddUser") && (
        <Route
          path="/users/add"
          element={
            <Frame>
              <UserDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditUser") && (
        <Route
          path="/users/:userId"
          element={
            <Frame>
              <UserDetails editMode={true} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditUser") && (
        <Route
          path="/users/:userId/role/add"
          element={
            <Frame>
              <AddUserRole editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("ViewUser") && (
        <Route
          path="/users"
          element={
            <Frame>
              <Users />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("AddLibrary") && (
        <Route
          path="/library/add"
          element={
            <Frame>
              <LibraryDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditLibrary") && (
        <Route
          path="/library/:libraryId/edit"
          element={
            <Frame>
              <LibraryDetails editMode={true} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("AddPiece") && (
        <Route
          path="/library/:libraryId/add"
          element={
            <Frame>
              <LibraryPieceDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditPiece") && (
        <Route
          path="/library/:libraryId/:pieceId/edit"
          element={
            <Frame>
              <LibraryPieceDetails editMode={true} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("AddPart") && (
        <Route
          path="/library/:libraryId/:pieceId/add"
          element={
            <Frame>
              <LibraryPartDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditPart") && (
        <Route
          path="/library/:libraryId/:pieceId/:partId/edit"
          element={
            <Frame>
              <LibraryPartDetails editMode={true} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("EditPart") && (
        <Route
          path="/library/:libraryId/:pieceId/:partId/add"
          element={
            <Frame>
              <PartInstrumentDetails editMode={false} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("EditPart") && (
        <Route
          path="/library/:libraryId/:pieceId/:partId"
          element={
            <Frame>
              <PartInstruments />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("EditPart") && (
        <Route
          path="/library/:libraryId/:pieceId"
          element={
            <Frame>
              <LibraryParts editMode={true} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("EditPiece") && (
        <Route
          path="/library/:libraryId"
          element={
            <Frame>
              <LibraryPieces editMode={true} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("ViewLibrary") && (
        <Route
          path="/library"
          element={
            <Frame>
              <Library />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("CreateInstrument") && (
        <Route
          path="/instrument/add"
          element={
            <Frame>
              <InstrumentDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditInstrument") && (
        <Route
          path="/instrument/:instrumentId/edit"
          element={
            <Frame>
              <InstrumentDetails editMode={true} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("CreateInstrumentSynonym") && (
        <Route
          path="/instrument/:instrumentId/add"
          element={
            <Frame>
              <InstrumentSynonymDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditInstrumentSynonym") && (
        <Route
          path="/instrument/:instrumentId/:synonymId/edit"
          element={
            <Frame>
              <InstrumentSynonymDetails editMode={true} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("ViewInstrumentSynonym") && (
        <Route
          path="/instrument/:instrumentId"
          element={
            <Frame>
              <InstrumentSynonyms />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("ViewInstrument") && (
        <Route
          path="/instrument"
          element={
            <Frame>
              <Instruments />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("CreateRole") && (
        <Route
          path="/security/add"
          element={
            <Frame>
              <AddRole editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditRole") && (
        <Route
          path="/security/:roleId"
          element={
            <Frame>
              <AddRole editMode={true} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("ViewSecurity") && (
        <Route
          path="/security"
          element={
            <Frame>
              <Security />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("ViewPerformance") && (
        <Route
          path="/performance/:host/:controller/:action/:requestType"
          element={
            <Frame>
              <PerformanceDetails />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("ViewPerformance") && (
        <Route
          path="/performance"
          element={
            <Frame>
              <Performance />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("ViewRole") && (
        <Route
          path="/role/:roleId"
          element={
            <Frame>
              <RoleDetails editMode={true} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("CreateUserRole") && (
        <Route
          path="/role/:roleId/user/add"
          element={
            <Frame>
              <AddRoleUser editMode={false} />
            </Frame>
          }
        />
      )}

      {authenticationService.hasAccess("ViewSsoProviders") && (
        <Route
          path="/ssoManager"
          element={
            <Frame>
              <SsoManager />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("AddSsoProvider") && (
        <Route
          path="/ssoManager/add"
          element={
            <Frame>
              <SsoProviderDetails editMode={false} />
            </Frame>
          }
        />
      )}
      {authenticationService.hasAccess("EditSsoProvider") && (
        <Route
          path="/ssoManager/edit/:ssoProviderId"
          element={
            <Frame>
              <SsoProviderDetails editMode={true} />
            </Frame>
          }
        />
      )}

      {authentication.hasAccess("ViewAuditLog") && (
        <Route
          path="/audit"
          element={
            <Frame>
              <AuditLog />
            </Frame>
          }
        />
      )}

      <Route
        path="/viewLibrary/:libraryId"
        element={
          <Frame>
            <LibraryViewer />
          </Frame>
        }
      />

      {profileRoute}

      <Route
        path="/logout"
        element={
          <Frame>
            <Logout />
          </Frame>
        }
      />

      <Route
        path="/"
        element={
          <Frame>
            <HomePage />
          </Frame>
        }
      />
    </>
  );
}

function App() {
  let navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(async () => {
      if (authentication.hasToken()) {
        await authentication.refreshToken();

        if (authentication.tokenExpired()) {
          navigate("/login");
          authentication.logout();
        }
      }
    }, 10 * 1000);
    return () => clearInterval(timer);
  });

  const isSignedIn = authentication.getCurrentUser() != null;

  const secureRoutes = isSignedIn ? (
    GetSecureRoutes()
  ) : (
    <Route path="/" element={<Navigate to="/login" />} />
  );

  var htmlAttributes: HtmlProps = {
    "data-bs-theme": theme.getPreferredTheme(),
  };

  const loginRoute = window.__RUNTIME_CONFIG__.EXTERNAL_LOGIN ? (
    <Route path="/login" element={<Redirect to="/account/login" />} />
  ) : (
    <Route
      path="/login"
      element={
        <LoggedOutFrame>
          <LoginForm />
        </LoggedOutFrame>
      }
    />
  );

  return (
    <HelmetProvider>
      <Helmet htmlAttributes={htmlAttributes}>
        <title>{config.applicationName}</title>
      </Helmet>
      <main className="container">
        <Routes>
          <Route path="/env" element={<EnvPage />} />
          {loginRoute}
          <Route
            path="/forgot-password"
            element={
              <LoggedOutFrame>
                <ForgotPassword />
              </LoggedOutFrame>
            }
          />
          <Route
            path="/404"
            element={
              <LoggedOutFrame>
                <NotFound />
              </LoggedOutFrame>
            }
          />
          <Route
            path="/emailuseraction/:token"
            element={
              <LoggedOutFrame>
                <EmailUserAction />
              </LoggedOutFrame>
            }
          />
          {secureRoutes}
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
        <ToastContainer />
      </main>
    </HelmetProvider>
  );
}

export default App;
