import React from "react";
import Column from "../../components/common/columns";
import Table, { PublishedTableProps } from "../../components/common/Table";
import { Paginated } from "../../services/Paginated";
import { AuditLogEntry } from "../../services/auditService";

interface AuditFieldValues {
  OldDisplayName?: string;
  OldValue?: string;
  NewDisplayName?: string;
  NewValue?: string;
}

interface AuditFieldChanges {
  [fieldname: string]: AuditFieldValues;
}

interface AuditFieldChangeValues {
  fieldName: string;
  oldDisplayName?: string;
  oldValue?: string;
  newDisplayName?: string;
  newValue?: string;
}

class AuditTable extends React.Component<PublishedTableProps<AuditLogEntry>> {
  fieldColumns: Column<AuditFieldChangeValues>[] = [
    { key: "fieldName", label: "Field" },
    {
      key: "oldDisplayName",
      label: "Old Value",
      content: (item) => {
        if (item.oldDisplayName !== undefined)
          return <>{item.oldDisplayName}</>;
        return <>{item.oldValue !== undefined ? String(item.oldValue) : ""}</>;
      },
    },
    {
      key: "newDisplayName",
      label: "New Value",
      content: (item) => {
        if (item.newDisplayName !== undefined)
          return <>{item.newDisplayName}</>;
        return <>{item.newValue !== undefined ? String(item.newValue) : ""}</>;
      },
    },
  ];

  columns: Column<AuditLogEntry>[] = [
    { key: "dateTime", label: "Timing", order: "asc", searchable: false },
    { key: "userDisplayName", label: "User Name", order: "asc" },
    { key: "comment", label: "Comment", order: "asc" },
    {
      key: "entityDisplayName",
      label: "Entity Display Name",
      order: "asc",
      searchable: false,
    },
    { key: "type", label: "Type", order: "asc" },
    { key: "displayName", label: "DisplayName", order: "asc" },

    {
      key: "fields",
      label: "Changes",
      order: "asc",
      searchable: false,
      content: (item) => {
        if (item.type === "Delete" || item.type === "Purge") {
          if (item.displayName !== "") return <></>;
        }

        const fieldsObject: AuditFieldChanges = JSON.parse(item!.fields);

        let data: AuditFieldChangeValues[] = [];
        Object.keys(fieldsObject).forEach((key, index) => {
          let dataItem: AuditFieldChangeValues = {
            fieldName: key,
            oldValue: fieldsObject[key].OldValue,
            oldDisplayName: fieldsObject[key].OldDisplayName,
            newValue: fieldsObject[key].NewValue,
            newDisplayName: fieldsObject[key].NewDisplayName,
          };

          data.push(dataItem);
        });

        let paginated: Paginated<AuditFieldChangeValues> = {
          count: 0,
          page: 1,
          pageSize: 10,
          totalPages: 0,
          data: data,
        };

        let fieldColumns: Column<AuditFieldChangeValues>[];
        fieldColumns = this.fieldColumns;

        if (item.type === "Create" || item.type === "Restore") {
          fieldColumns = this.fieldColumns.filter(
            (x) => x.key !== "oldDisplayName"
          );
        }

        if (item.type === "Delete" || item.type === "Purge") {
          fieldColumns = this.fieldColumns.filter(
            (x) => x.key !== "newDisplayName"
          );
        }

        return <Table data={paginated} keyName="id" columns={fieldColumns} />;
      },
    },
  ];

  raiseSort = (sortColumn: Column<AuditLogEntry>) => {
    this.setState({ sortColumn });
    if (this.props.onSort !== undefined) this.props.onSort(sortColumn);
  };

  handleAuditParams = (item: any) => {
    return {
      entityName: item.entityName,
      primaryKey: item.primaryKey,
    };
  };

  render() {
    const { data, sortColumn, onChangePage, onSearch } = this.props;

    return (
      <Table
        data={data}
        keyName="id"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={this.raiseSort}
        onChangePage={onChangePage}
        onSearch={onSearch}
        onAuditParams={this.handleAuditParams}
        secondaryAudit={true}
      />
    );
  }
}

export default AuditTable;
